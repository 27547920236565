export const WEEZEVENT_APP_KEY = '31f5809f43b3161dd8aefb7a79a5fc55'
export const WEEZEVENT_SYSTEM_ID = 'payutc'
export const WEEZEVENT_URL = 'https://api.nemopay.net/services'
export const FUND_ID = 2
export const EVENT_ID = 1

export const API_URL = 'https://kraken.picasso-utc.fr/api'

export const WEBSOCKET_URL = 'ws://localhost:9191/events'

export const EXONERATION_UIDS = [
	'4B52D3EC'
]
