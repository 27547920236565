import React from "react";

const MenuContainer = () => {
  return (
    <div>
      Menu container
    </div>
  )
}

export default MenuContainer;
